.apps {
  padding: 130px 0 55px;
  @include media("<=tablet") {
    padding: 80px 0 40px;
    overflow: hidden;
  }
  @include media("<=420px") {
    padding: 40px 0 20px;
  }
  .section-title {
    @include media("<=1024px") {
      width: 501px;
    }
    @include media("<=tablet") {
      width: 461px;
    }
    @include media("<=420px") {
      width: auto;
    }
  }
}

.apps-inner {
  display: flex;
  @include media("<=1024px") {
    padding-left: 40px;
    align-items: center;
  }
  @include media("<=420px") {
    flex-direction: column;
    padding: 0 20px;
  }
}

.apps-content {
  flex: 0 1 100%;
  max-width: 501px;
}

.apps-text {
  max-width: 480px;
  color: #68767b;
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 48px;
  line-height: 1.1;

  opacity: 0;
  transition: all 0.8s ease 0.2s;

  &._active {
    opacity: 1;
  }

  @include media("<=420px") {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

.apps-list {
  margin-left: 30px;
  color: #68767b;
  font-size: 25px;
  font-weight: 300;
  @include media("<=tablet") {
    font-size: 22px;
  }
  @include media("<=420px") {
    font-size: 18px;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    @include media("<=420px") {
      width: 50%;
      padding-left: 33px;
    }
  }
}

.apps-img {
  padding-top: 10px;
  position: relative;
  left: -4%;
  flex: 0 1 100%;
  max-width: 599px;
  @include media("<=tablet") {
    left: -15%;
  }
  @include media("<=420px") {
    left: 0;
    padding-top: 0;
  }
  img {
    width: 100%;
    @include media("<=tablet") {
      width: 140%;
    }
    @include media("<=420px") {
      width: 100%;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

@font-face {
  font-family: "Gotham-ProBlack";
  src: url("../fonts/GothaProBla.eot");
  src: local("☺"), url("../fonts/GothaProBla.woff") format("woff"),
    url("../fonts/GothaProBla.ttf") format("truetype"),
    url("../fonts/GothaProBla.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Pro";
  src: url("../fonts/GothaProMed.eot");
  src: local("☺"), url("../fonts/GothaProMed.woff") format("woff"),
    url("../fonts/GothaProMed.ttf") format("truetype"),
    url("../fonts/GothaProMed.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

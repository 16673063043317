.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0 3px;
  @include media("<=1200px") {
    padding: 13px 40px 3px;
  }
  @include media("<=420px") {
    padding: 13px 20px 3px;
  }
}

.header-logo {
  @include media("<=420px") {
    max-width: 180px;
  }
  img {
    width: 100%;
  }
}

.header-nav {
  @include media("<=tablet") {
    display: none;

    &.active {
      display: flex;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      z-index: 20;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 0.95;

      .header-link {
        padding: 20px;
        margin-left: 0;
      }
    }
  }
}

.header-link {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: #0086d0;
  line-height: 22.69px;
  margin-left: 50px;
  transition: color 0.3s linear;
}

.header-link:hover {
  color: #000;
}

.partners {
  padding: 200px 0;
  background-image: url(../images/partners-bg.png);
  background-repeat: no-repeat;
  background-position-x: center;
  @include media("<=tablet") {
    padding: 130px 0;
  }
  @include media("<=420px") {
    padding: 100px 0;
    background-size: cover;
  }
  .section-title {
    text-align: center;
  }
}

.partners-inner {
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  align-items: center;
  @include media("<=1024px") {
    padding: 0 40px;
  }
  @include media("<=tablet") {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 140px;
  }
  @include media("<=420px") {
    padding: 0 20px;
  }
}

.partners-item {
  width: 100%;
  max-width: 163px;
  cursor: pointer;
  transition: all 0.3s linear;
  img {
    @include media("<=420px") {
      width: 100%;
    }
  }
  @include media("<=tablet") {
    flex: 0 1 50%;
    margin: 0 auto 20px;
  }
  @include media("<=420px") {
    max-width: 140px;
  }
  @include media("<=320px") {
    max-width: 110px;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.contacts {
  padding: 75px 0 40px;
  .section-title {
    text-align: center;
  }
}

.contacts-inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 60px;
  @include media("<=420px") {
    flex-direction: column;
  }
}

.contacts-img {
  width: 100%;
  max-width: 398px;
  @include media("<=tablet") {
    margin-right: 30px;
  }
  @include media("<=420px") {
    margin-right: 0;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
  }
}

.contacts-email {
  color: #0086d0;
  border: 3px solid #0086d0;
  font-size: 20px;
  font-family: "Gotham-Pro";
  padding: 20px 27px;
  position: relative;
  left: -4%;
  @include media("<=420px") {
    left: 0;
    font-size: 16px;
    padding: 18px 8px;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}

.about {
  padding: 145px 0 165px;
  @include media("<=tablet") {
    padding-top: 0;
    padding-bottom: 140px;
  }
  @include media("<=420px") {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  &-inner {
    padding: 0 35px;
    display: flex;
    justify-content: space-between;
    @include media("<=tablet") {
      align-items: center;
    }
    @include media("<=420px") {
      flex-direction: column-reverse;
      padding: 0 25px;
    }
  }
}

.about-img {
  flex: 0 1 100%;
  max-width: 496px;
  @include media("<=1024px") {
    margin-right: 20px;
  }
  @include media("<=420px") {
    margin-right: 0;
  }
  img {
    width: 100%;
  }
}

.about-content {
  max-width: 496px;
  flex: 0 1 100%;
  padding-top: 30px;
  @include media("<=tablet") {
    padding-top: 0;
  }
  @include media("<=420px") {
    margin-bottom: 15px;
  }
  .about-text {
    color: #68767b;
    font-size: 19px;
    margin-bottom: 25px;

    opacity: 0;

    &._active {
      opacity: 1;
    }

    @include media("<=tablet") {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
}

.about-text._active:nth-child(2) {
  transition: all 0.8s ease 0.2s;
}

.about-text._active:nth-child(3) {
  transition: all 0.8s ease 0.4s;
}

.about-text._active:last-child {
  transition: all 0.8s ease 0.6s;
}

.hero {
  background-image: url(../images/hero-bg.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 60%;
  padding-bottom: 165px;
  @include media("<=1440px") {
    background-position-y: bottom;
    background-size: contain;
    padding-bottom: 100px;
  }
  @include media("<=1280px") {
    background-size: 70%;
    padding-bottom: 50px;
  }
  @include media("<=1024px") {
    padding-bottom: 90px;
  }
  @include media("<=tablet") {
    background-position-y: center;
    padding-bottom: 220px;
  }
  @include media("<=420px") {
    background-position-y: bottom;
    padding-bottom: 20px;
    background-size: 95%;
  }
}

.hero-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 86px;
  padding-right: 193px;
  @include media("<=1280px") {
    padding: 85px 80px 0 40px;
  }
  @include media("<=tablet") {
    padding: 70px 0 0 40px;
  }
  @include media("<=700px") {
    flex-direction: column;
  }
  @include media("<=420px") {
    padding: 40px 20px 0;
  }
}

.hero-content {
  .hero-title {
    font-family: "Gotham-ProBlack";
    font-weight: 900;
    font-size: 61px;
    line-height: 1.1;
    max-width: 482px;
    padding-top: 29px;
    margin-bottom: 50px;

    opacity: 0;
    transition: all 0.8s ease 0.2s;

    &._active {
      opacity: 1;
    }

    @include media("<=tablet") {
      padding-top: 0;
    }
    @include media("<=420px") {
      font-size: 40px;
      margin-bottom: 35px;
    }
    @include media("<=320px") {
      font-size: 34px;
    }
  }
  .hero-list {
    color: #68767b;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 88px;
    @include media("<=tablet") {
      margin-bottom: 170px;
    }
    @include media("<=420px") {
      font-size: 20px;
      margin-bottom: 220px;
    }
    @include media("<=320px") {
      margin-bottom: 140px;
    }
  }
}

li {
  position: relative;
  margin-bottom: 28px;
  padding-left: 43px;
  @include media("<=420px") {
    margin-bottom: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: linear-gradient(
      135deg,
      rgba(84, 205, 255, 1) 0%,
      rgba(0, 61, 113, 1) 100%
    );
    @include media("<=420px") {
      top: -3px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 9px;
    display: inline-block;
    transform: rotate(45deg);
    height: 16px;
    width: 9px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid #fff;
    @include media("<=420px") {
      top: 2px;
    }
  }
}

.list-item {
  opacity: 0;
  transition: all 0.3s ease 0s;
  &._active {
    opacity: 1;
  }
}

.list-item._active:nth-child(1) {
  transition: all 0.8s ease 0.4s;
}

.list-item._active:nth-child(2) {
  transition: all 0.8s ease 0.6s;
}

.list-item._active:nth-child(3) {
  transition: all 0.8s ease 0.8s;
}

.list-item._active:nth-child(4) {
  transition: all 0.8s ease 1s;
}

.list-item._active:nth-child(5) {
  transition: all 0.8s ease 1.2s;
}

.hero-button {
  text-align: center;
  font-family: "Gotham-Pro";
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  padding: 22px 60px;
  border: 3px solid #0086d0;
  background-color: #fff;
  transition: all 0.3s linear;
  @include media("<=420px") {
    display: block;
    width: 75%;
    margin: 0 auto;
    font-size: 18px;
    padding: 20px 55px;
    @include media("<=320px") {
      padding: 18px;
    }
  }
  &:hover {
    background-color: #0086d0;
    color: #fff;
  }
}

.hero-img {
  position: relative;
  width: 100%;
  max-width: 276px;
  height: 559px;
  @include media("<=tablet") {
    max-width: 240px;
    top: 150px;
    right: 60px;
  }
  @include media("<=700px") {
    top: -350px;
    left: 350px;
    height: auto;
  }
  @include media("<=420px") {
    max-width: 140px;
    left: 190px;
    top: -385px;
  }
  @include media("<=320px") {
    max-width: 110px;
    left: 170px;
    top: -305px;
  }
}

.hero-gif {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

.hero-icon {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  animation-name: fade;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  &.icon1 {
    animation-delay: 0s;
  }
  &.icon2 {
    animation-delay: 2s;
  }
  &.icon3 {
    animation-delay: 4s;
  }
  &.icon4 {
    animation-delay: 6s;
  }
  &.icon5 {
    animation-delay: 8s;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.footer {
  background-color: #000;
  color: #68767b;
  font-size: 19px;
  font-weight: 300;
  text-align: center;
  padding: 28px 0;
  @include media("<=420px") {
    padding: 24px 0;
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 10px;
}

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.section-title {
  font-family: "Gotham-ProBlack";
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 35px;
  @include media("<=tablet") {
    font-size: 46px;
    margin-bottom: 25px;
  }
  @include media("<=420px") {
    font-size: 36px;
    margin-bottom: 15px;
    text-align: center;
  }
}

//BURGER BUTTON//
.burger-button {
  display: none;

  @include media("<=tablet") {
    display: block;
    position: fixed;
    top: 26px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=420px") {
    top: 26px;
    right: 20px;
  }

  @include media("<=tablet") {
    &.active {
      position: fixed;
      top: 26px;
      right: 40px;
      z-index: 200;
    }
    @include media("<=420px") {
      &.active {
        top: 26px;
        right: 20px;
      }
    }
  }

  .line {
    height: 8px;
    width: 56px;
    margin-bottom: 8px;
    background: #0086d0;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=420px") {
      height: 7px;
      width: 48px;
      margin-bottom: 6px;
    }
  }

  &.active {
    .line {
      &.one {
        transform: rotate(45deg) scale(1.2) translateY(1px) translateX(4px);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-6px) translateX(8px);
        @include media("<=420px") {
          transform: rotate(-45deg) scale(1.2) translateY(-4px) translateX(6px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}

.why {
  background-image: url(../images/why-bg.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding: 88px 0 170px;
  @include media("<=tablet") {
    padding: 80px 0 150px;
    background-size: cover;
  }
  @include media("<=420px") {
    padding: 60px 0 90px;
  }
  .section-title {
    color: #fff;
    text-align: center;
  }
}

.why-inner {
  display: flex;
  justify-content: space-between;
  padding: 25px 45px 0;
  @include media("<=tablet") {
    align-items: flex-start;
    padding: 5px 25px 0;
  }
  @include media("<=420px") {
    flex-direction: column;
    align-items: center;
  }
}

.why-item {
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include media("<=tablet") {
    max-width: 235px;
  }
  @include media("<=420px") {
    max-width: 300px;
    margin-bottom: 30px;
  }
  img {
    @include media("<=tablet") {
      width: 100%;
    }
  }
  &__img {
    height: 300px;
    margin-bottom: 42px;
    @include media("<=tablet") {
      height: 250px;
    }
    @include media("<=420px") {
      height: auto;
      max-width: 270px;
      margin: 0 auto 25px;
    }
  }
  &__title {
    text-align: center;
    color: #fff;
    font-family: "Gotham-Pro";
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;

    opacity: 0;
    transition: all 0.8s ease 0.2s;

    &._active {
      opacity: 1;
    }

    @include media("<=tablet") {
      font-size: 20px;
      margin-bottom: 20px;
    }
    @include media("<=420px") {
      margin-bottom: 15px;
    }
  }
  &__text {
    color: #fff;
    font-size: 19px;
    font-weight: 400;

    opacity: 0;
    transition: all 0.8s ease 0.4s;

    &._active {
      opacity: 1;
    }

    @include media("<=tablet") {
      font-size: 16px;
    }
  }
}
